body {
  background-color: #0f0d13;
  width: 100%;
}
html {
  width: 100%;
}
pre {
  font-family: inherit;
}
.text-outline-brown {
  -webkit-font-smoothing: antialiased;
  text-shadow: #522410 0 0 4px, #522410 0 0 4px, #522410 0 0 4px, #522410 0 0 4px, #522410 0 0 4px, #522410 0 0 4px !important;
}
.text-outline-orange {
  -webkit-font-smoothing: antialiased;
  text-shadow: #522410 0 0 4px, #522410 0 0 4px, #522410 0 0 4px, #522410 0 0 4px, #522410 0 0 4px, #522410 0 0 4px !important;
}
